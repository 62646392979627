import {Button} from 'flowbite-react';
import React from "react";
import {useNavigate} from "react-router-dom";
import {ImageFile} from "../api";

interface CustomOfferCardProps {
    image: ImageFile | null;
    night_stay: number;
    title: string;
    location: string;
    company: string | null;
    price: number;
    member_price: number;
    currency: string;
    description: string | null;
}

function CustomOfferCard({image, night_stay, title, location, company, price, member_price, currency, description} : CustomOfferCardProps) {

    const navigate = useNavigate();

    function navigateToContacts() { navigate("/contacts"); }


    function mapCurrencyToSymbol(currency: string) {
        switch (currency) {
            case "EUR":
                return "€";
            case "USD":
                return "$";
            case "GBP":
                return "£";
            default:
                return "";
        }
    }

    const decodedImageSrc = image ? `${image.encoded_data}` : null;

    return (
        <div className="flex flex-col w-full h-full bg-white border border-gray-200 rounded-lg shadow">
            <div className="rounded-t-lg relative h-1/3">
                <img src={decodedImageSrc || "/images/placeholder.jpeg"} alt={title + "_image"}
                     className="rounded-t-lg h-full w-full object-cover"/>

                    <a href="https://7across.com/upgrade/gold/?utm_source=website&utm_medium=banner&utm_campaign=Free+Member+GA+Upgrade+Banner"
                       target="_blank"
                       className="absolute bottom-2 right-2 rounded-lg border border-primary bg-tertiary p-1 block leading-4 text-xs text-primary hover:text-opacity-90 hover:bg-opacity-90 shadow-md">
                        Diventa socio <b>Gold Advantage</b><br/> per ottenere ulteriori benefici!
                    </a>
            </div>

            <div className="flex flex-col h-1/3 py-2 px-2">
                <span className="flex justify-center text-lg">{night_stay} {night_stay > 1 ? "nights" : "night"}</span>
                <span className="flex justify-center text-center text-xl font-bold text-gray-900">{title}</span>
                {description &&
                    <p className="flex justify-center text-center text-sm font-normal text-gray-700 tracking-tighter">{description}</p>}
            </div>

            <div className="flex flex-col h-1/3 py-2 justify-end">
                <span className="flex justify-center text-lg">{location}</span>
                {company && company !== "Altro" &&
                    <img src={("/images/companies/" + company + ".png")} alt={company}
                         className="flex justify-center m-auto rounded-lg h-10"/>
                }

                <div className="flex justify-between mt-2 px-4 items-center bg-primary">
                    <div className="flex flex-col text-center text-white">
                        <span className="text-md font-semibold">Prezzo</span>
                        <span className="text-xl font-bold line-through">{mapCurrencyToSymbol(currency)} {price}</span>
                        <span className="text-md font-semibold">a persona</span>
                    </div>
                    <div className="flex flex-col text-center text-tertiary">
                        <span className="text-md font-semibold">Prezzo Soci</span>
                        <span className="text-xl font-bold">{mapCurrencyToSymbol(currency)} {member_price}</span>
                        <span className="text-md font-semibold">a persona</span>
                    </div>
                    <div className="flex flex-col text-center text-white">
                        <span className="text-md font-semibold">Sconto Soci</span>
                        <span
                            className="text-xl font-bold">{mapCurrencyToSymbol(currency)} {(price - member_price) * 2}</span>
                        <span className="text-md font-semibold">a coppia</span>
                    </div>
                </div>

                <div className="flex justify-center mt-2">
                    <Button onClick={navigateToContacts} size="xl"
                            className="h-10 text-black bg-secondary hover:text-opacity-70 hover:bg-opacity-70 shadow-md">
                        Contattaci
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default CustomOfferCard;