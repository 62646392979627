import React from 'react';
import {Alert, Button, CustomFlowbiteTheme, Label, Textarea, TextInput} from "flowbite-react";
import {HiOutlinePhone, HiOutlineMail, HiOutlineClock, HiInformationCircle} from "react-icons/hi";

function Contacts() {

    const phone = '045 2588694'
    const email = 'info.italia@7across.com';

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const name = (event.currentTarget.elements.namedItem('name') as HTMLInputElement)?.value;
        const surname = (event.currentTarget.elements.namedItem('surname') as HTMLInputElement)?.value;
        const object = (event.currentTarget.elements.namedItem('object') as HTMLInputElement)?.value;
        const body = (event.currentTarget.elements.namedItem('body') as HTMLTextAreaElement)?.value;

        // Open default email client
        window.location.href = `mailto:${email}?subject=[7AcrossIta] - ${name} ${surname} - ${object}&body=${body}`;
    };

    const CustomAlertTheme: CustomFlowbiteTheme['alert'] = {
        "color": {
            "info": "text-primary bg-blue-100 border-primary",
        }
    }

    return (
        <div>

            <div className="my-8">
                <div className="justify-center mx-auto w-full my-4">
                    <p className=" text-center text-lg px-4">
                        Contattaci per qualsiasi informazione o dubbio, saremo lieti di risponderti il prima
                        possibile!<br/>
                        Puoi trovare i nostri recapiti ed i nostri orari di seguito.
                    </p>
                </div>
                <div className="justify-center mx-auto max-w-xl w-full my-4">
                    <Alert
                        theme={CustomAlertTheme}
                        icon={HiInformationCircle}
                        additionalContent={
                            <div>
                                <div className="flex my-2">
                                    <div className="w-1/2 flex items-center justify-center">
                                        <div className="flex items-center rounded-lg border border-primary bg-gradient-to-r from-secondary to-tertiary">
                                            <HiOutlinePhone className="h-8 w-8 stroke-1 text-primary m-2"/>
                                            <span className="font-bold text-lg m-2">{phone}</span>
                                        </div>
                                    </div>

                                    <div className="border-r border-primary"></div>

                                    <div className="w-1/2 flex items-center justify-center">
                                        <div className="flex items-center justify-center">
                                            <HiOutlineClock className="h-8 w-8 stroke-1 text-primary my-2 mr-2"/>
                                            <span className="text-md text-center flex-shrink-0">
                                                <b>Orario centralino:</b><br/>
                                                Dal <b>Lunedì</b> al <b>Venerdì</b><br/>
                                                <b>10:00 - 13:00</b>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-1/2">
                                    <div className="flex items-center justify-center">
                                        <HiOutlineMail className="h-8 w-8 stroke-1 text-primary m-2"/>
                                        <span className="text-lg">{email}</span>
                                    </div>
                                </div>
                            </div>
                        }
                        color="info"
                        rounded>
                        <span className="font-semibold text-xl">Contatti</span>
                    </Alert>
                </div>
            </div>

            <div className="my-8">
                <div className="justify-center mx-auto w-full my-4">
                    <p className=" text-center text-lg px-4">
                        Puoi anche utilizzare il form sottostante per aiutarti nella compilazione della mail.
                    </p>
                </div>
                <form className="flex-col justify-center mx-auto max-w-md my-4" onSubmit={handleSubmit}>
                    <div className="max-w-md w-full mb-2">
                        <div className="mb-2 block">
                            <Label htmlFor="name" value="Nome"/>
                        </div>
                        <TextInput id="name"
                                   type="text"
                                   sizing="md"
                                   placeholder="Mario"
                                   required/>
                    </div>

                    <div className="max-w-md w-full mb-2">
                        <div className="mb-2 block">
                            <Label htmlFor="surname" value="Cognome"/>
                        </div>
                        <TextInput id="surname"
                                   type="text"
                                   sizing="md"
                                   placeholder="Rossi"
                                   required/>
                    </div>

                    <div className="max-w-md w-full mb-2">
                        <div className="mb-2 block">
                            <Label htmlFor="object" value="Oggetto"/>
                        </div>
                        <TextInput id="object"
                                   type="text"
                                   sizing="md"
                                   placeholder="Oggetto..."
                                   required/>
                    </div>

                    <div className="max-w-md w-full mb-4">
                        <div className="mb-2 block">
                            <Label htmlFor="body" value="Descrizione"/>
                        </div>
                        <Textarea id="body"
                                  placeholder="Descrizione..."
                                  rows={5}
                                  required/>
                    </div>

                    <Button type="submit"
                            size="md"
                            className="max-w-md w-full text-primary bg-secondary hover:text-opacity-70 hover:bg-opacity-70 shadow-md">
                        Invia
                    </Button>

                </form>
            </div>

        </div>
    );
}

export default Contacts;
