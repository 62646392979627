import {Accordion, Alert, Button, CustomFlowbiteTheme, Modal, Table} from "flowbite-react";
import {HiInformationCircle, HiTrash} from "react-icons/hi";
import React, {useEffect, useState} from "react";
import CreateUserForm from "../../../components/CreateUserForm";
import {AdminService, type UserPublicInfo} from "../../../api";
import {dispatchResponseReceivedEvent} from "../../../events/events";
import {hasAdminPermission} from "../../../auth/utilities";

function EditUsers() {

    const [users, setUsers] = useState<Array<UserPublicInfo>>([]);
    const [userToDelete, setUserToDelete] = useState<UserPublicInfo | null>(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isAdmin] = useState<boolean | null>(hasAdminPermission());



    useEffect(() => {
        fetchUsers().then();
        document.addEventListener('userCreated', refreshCreatedUser);

        return () => {
            document.removeEventListener('userCreated', refreshCreatedUser);
        };
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await AdminService.getUsers();
            setUsers(response);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    function refreshCreatedUser() {
        fetchUsers().then();
    }

    function refreshDeletedUser() {
        fetchUsers().then();
    }

    function prepareUserForDeletion(user: UserPublicInfo) {
        setUserToDelete(user)
        setOpenDeleteModal(true)
    }

    function deleteUser() {
        if (userToDelete) {
            AdminService.deleteUser(userToDelete.username).then(response => {
                dispatchResponseReceivedEvent("success", response.message);
                refreshDeletedUser();
            }).catch((error) => {
                dispatchResponseReceivedEvent("error", error.body?.detail);
            });
        }
        setOpenDeleteModal(false);
    }

    const CustomAlertTheme: CustomFlowbiteTheme['alert'] = {
        "color": {
            "info": "text-primary bg-blue-100 border-primary",
        }
    }

    const CustomTableTheme: CustomFlowbiteTheme['table'] = {
        head: {
            base: "group/head text-xs uppercase text-primary dark:text-gray-400",
            cell: {
                base: "group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg bg-tertiary dark:bg-gray-700 px-6 py-3"
            }
        }
    }

    const CustomAccordionTitleTheme= {
        base: "flex w-full items-center justify-between py-5 px-5 text-left font-medium text-gray-500 dark:text-gray-400",
        flush: {
            off: "hover:bg-gray-100"
        },
        open: {
            off: "rounded-lg text-gray-900",
            on: "rounded-t-lg text-gray-900 bg-gray-100 dark:bg-gray-800 dark:text-white"
        }
    }

    return (
        <div className="flex flex-col px-4 min-h-screen gap-y-4">
            <Alert
                theme={CustomAlertTheme}
                additionalContent={<p className="text-base">
                    Di seguito puoi trovare la lista di utenti che hanno accesso all' Admin Panel.
                    E' possibile eliminare un utente cliccando sull'icona del cestino.
                    Inoltre, è possibile creare un nuovo utente tramite l'apposito modulo.</p>}
                color="info"
                icon={HiInformationCircle}
                rounded>
                <span className="font-semibold text-base">Sezione dedicata alla gestione degli utenti!</span>
            </Alert>

            <div className="flex w-full mx-auto gap-x-4">
                <div className="w-1/2">
                    <div className="overflow-x-auto border rounded-lg shadow ">
                        <Table striped theme={CustomTableTheme}>
                            <Table.Head>
                                <Table.HeadCell>Username</Table.HeadCell>
                                <Table.HeadCell>
                                    <span className="sr-only">Delete</span>
                                </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {users && users.length > 0 ? (users.map((user, index) => (
                                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={index}>
                                        <Table.Cell
                                            className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                            {user.username}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {isAdmin &&
                                                <button onClick={() => prepareUserForDeletion(user)}
                                                    className="text-lg text-red-500 dark:text-cyan-500">
                                                    <HiTrash/>
                                                </button>
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                ))) : (
                                    <Table.Row>
                                        <Table.Cell>
                                            No users found.
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                {isAdmin &&
                    <div className="w-1/3 flex justify-center mx-auto">
                        <div className="w-full">
                            <Accordion collapseAll>
                                <Accordion.Panel>
                                    <Accordion.Title theme={CustomAccordionTitleTheme}>Crea nuovo utente</Accordion.Title>
                                    <Accordion.Content>
                                        <CreateUserForm/>
                                    </Accordion.Content>
                                </Accordion.Panel>
                            </Accordion>
                        </div>
                    </div>
                }
            </div>

            <Modal size="lg" show={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <Modal.Header>Elimina Utente</Modal.Header>
                <Modal.Body>
                    <div className="space-y-2">
                        <div>
                            {userToDelete !== null &&
                                <span>
                                Sei sicuro di voler eliminare l'utente
                                <span className="font-semibold"> {userToDelete.username}</span>?
                            </span>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="failure" onClick={() => deleteUser()}>Elimina</Button>
                    <Button color="gray" onClick={() => setOpenDeleteModal(false)}>Annulla</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default EditUsers;
