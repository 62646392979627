import React, {useEffect, useState} from 'react';
import {Tabs, Toast} from "flowbite-react";
import CustomAdminNavBar from "../../components/CustomAdminNavBar";
import { HiUserCircle, HiOutlinePlusCircle, HiCheck, HiExclamation, HiOutlineCog } from 'react-icons/hi';
import { MdDashboard } from 'react-icons/md';
import type { CustomFlowbiteTheme } from 'flowbite-react';
import CreateOffer from "./tabs/CreateOffer";
import EditOffers from "./tabs/EditOffers";
import EditUsers from "./tabs/EditUsers";
import Login from "./auth/Login";
import {ResponseMessage} from "../../events/events";
import MyUserSettings from "./tabs/MyUserSettings";


function Admin() {

    const [isAdminLogged, setAdminLogged] = useState(false);
    const [message, setMessage] = useState<ResponseMessage | null>(null);


    useEffect(() => {
        document.addEventListener('userAuthenticated', () => setAdminLogged(true));
        document.addEventListener('responseReceived', (event: Event) => {
            const customEvent = event as CustomEvent<ResponseMessage>;
            setMessage(customEvent.detail);
        });
        return () => {
            document.removeEventListener('userAuthenticated', () => setAdminLogged(true));
            document.addEventListener('responseReceived', (event: Event) => {
                const customEvent = event as CustomEvent<ResponseMessage>;
                setMessage(customEvent.detail);});
        };
    }, []);


    const customTabsTheme: CustomFlowbiteTheme['tabs'] = {
        tablist: {
            styles: {
                underline: "flex-wrap justify-center -mb-px border-b border-gray-200 dark:border-gray-700",
            },
            tabitem: {
                base: "flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500",
                styles: {
                    underline: {
                        active: {
                            on: "w-1/4 text-xl text-primary rounded-t-lg border-b-2 border-primary active dark:text-cyan-500 dark:border-cyan-500",
                            off: "w-1/4 text-xl border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300"
                        }
                    }
                }
            }
        }
    };

    const customSuccessToastTheme: CustomFlowbiteTheme['toast'] = {
        root: {
            base: "z-100 flex w-full max-w-md items-center rounded-lg border-2 border-green-500 bg-green-100 p-4 text-gray-900 shadow dark:bg-gray-800 dark:text-gray-400",
        },
        toggle: {
            base: "z-100 -mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-green-100 p-1.5 text-gray-900 hover:bg-green-200 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white",
        }
    }

    const customErrorToastTheme: CustomFlowbiteTheme['toast'] = {
        root: {
            base: "z-100 flex w-full max-w-md items-center rounded-lg border-2 border-red-600 bg-red-200 p-4 text-gray-900 shadow dark:bg-gray-800 dark:text-gray-400",
        },
        toggle: {
            base: "z-100 -mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-red-200 p-1.5 text-gray-900 hover:bg-red-300 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white",
        }
    }

    return (
        <div>
            {isAdminLogged
                ? ( <div>
                        <CustomAdminNavBar/>
                        <Tabs aria-label="Tabs with icons" style="underline" theme={customTabsTheme}>
                            <Tabs.Item title="Crea Offerta" icon={HiOutlinePlusCircle} active>
                                <CreateOffer/>
                            </Tabs.Item>
                            <Tabs.Item title="Gestisci Offerte" icon={MdDashboard}>
                                <EditOffers/>
                            </Tabs.Item>
                            <Tabs.Item active title="Gestisci Utenti" icon={HiUserCircle}>
                                <EditUsers/>
                            </Tabs.Item>
                            <Tabs.Item active title="Impostazioni Account" icon={HiOutlineCog}>
                                <MyUserSettings/>
                            </Tabs.Item>
                        </Tabs>

                        {message && (
                            <div id="toast-success" className="fixed flex w-full max-w-md items-center justify-center -translate-x-1/2 left-1/2 bottom-16 z-100" role="alert">
                                <Toast theme={message.type === "success" ? customSuccessToastTheme : customErrorToastTheme}>
                                    {message.type === "success" ? (
                                        <div className="-mx-1.5 -my-1.5 mr-auto h-8 w-8 rounded-lg p-1.5 text-green-500 focus:ring-2 focus:ring-gray-300 z-100">
                                            <HiCheck className="h-5 w-5" />
                                        </div>
                                    ) : (
                                        <div
                                            className="-mx-1.5 -my-1.5 mr-auto h-8 w-8 rounded-lg p-1.5 text-red-600 focus:ring-2 focus:ring-gray-300 z-100">
                                            <HiExclamation className="h-5 w-5" />
                                        </div>
                                    )}
                                    <div className="text-sm font-normal text-center w-full whitespace-pre-line z-100">{message.message}</div>
                                    <Toast.Toggle onDismiss={() => setMessage(null)} />
                                </Toast>
                            </div>
                        )}
                    </div>
                ) : ( <Login /> )
            }
        </div>
    );
}

export default Admin;
