import { Footer } from 'flowbite-react';

function CustomFooter() {
    return (
        <Footer container className="bg-primary rounded-none p-9">
            <div className="w-full">
                <div className="grid grid-cols-4 w-full justify-between">
                    <div>
                        <Footer.Brand href="/" src="/7Across-logo.png"/>
                    </div>
                    <div>
                        <Footer.Title title="Termini e condizioni"/>
                        <Footer.LinkGroup col>
                            <Footer.Link target="_blank" href="/terms-conditions">Termini e condizioni</Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <div>
                        <Footer.Title title="Informativa"/>
                        <Footer.LinkGroup col>
                            <Footer.Link target="_blank" href="/supplier">Fornitore</Footer.Link>
                            <Footer.Link target="_blank" href="/security-health-abroad">Sicurezza e salute all'estero</Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <div>
                        <Footer.Title title="Privacy"/>
                        <Footer.LinkGroup col>
                            <Footer.Link target="_blank"
                                         href="http://topdestinations.it/index.php/cookie-policy.html">Cookie
                                Policy</Footer.Link>
                            <Footer.Link target="_blank"
                                         href="http://topdestinations.it/index.php/privacy-policy.html">Privacy
                                Policy</Footer.Link>
                            <Footer.Link target="_blank"
                                         href="http://topdestinations.it/index.php/informativa-privacy.html">Informativa
                                Privacy</Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                </div>
            </div>
        </Footer>
    );
}

export default CustomFooter
