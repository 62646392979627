import React from 'react';
import {BrowserRouter, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import Home from './pages/home/Home';
import Cruise from "./pages/cruise/Cruise";
import Offer from "./pages/offer/Offer";
import TradeTravel from "./pages/tradetravel/TradeTravel";
import Contacts from "./pages/contacts/Contacts";
import Admin from "./pages/admin/Admin";

import CustomFooter from "./components/CustomFooter";
import CustomNavBar from "./components/CustomNavBar";
import TermsAndConditions from "./pages/footer/TermsAndConditions";
import Supplier from "./pages/footer/Supplier";
import SecurityAndHealthAbroad from "./pages/footer/SecurityAndHealthAbroad";

const NavBarWrapper = () => {
    const location = useLocation();
    const shouldRenderNavBar = !['/admin'].includes(location.pathname);

    return shouldRenderNavBar ? <CustomNavBar /> : null;
};

const FooterWrapper = () => {
    const location = useLocation();
    const shouldRenderFooter = !['/admin'].includes(location.pathname);

    return shouldRenderFooter ? <CustomFooter /> : null;
};

const NotFound = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        navigate('/');
    }, [navigate]);

    return null;
};

export function App () {

    return (
        <BrowserRouter>

            <NavBarWrapper />

            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/cruise" element={<Cruise/>}/>
                <Route path="/offer" element={<Offer/>}/>
                <Route path="/trade-travel" element={<TradeTravel/>}/>
                <Route path="/contacts" element={<Contacts/>}/>
                <Route path="/admin" element={<Admin/>}/>
                <Route path="/terms-conditions" element={<TermsAndConditions/>}/>
                <Route path="/supplier" element={<Supplier/>}/>
                <Route path="/security-health-abroad" element={<SecurityAndHealthAbroad/>}/>
                <Route path="*" element={<NotFound />} />
            </Routes>

            <FooterWrapper />

        </BrowserRouter>
    );
}

export default App;
