import {Button, CustomFlowbiteTheme, Label, TextInput, ToggleSwitch} from "flowbite-react";
import React, {FormEvent, useState} from "react";
import {AdminService} from "../api";
import {dispatchResponseReceivedEvent, dispatchUserCreatedEvent} from "../events/events";



export function CreateUserForm() {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [username, setUsername] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setIsFormSubmitted(true);

        if (validateForm()) {
            AdminService.createUser(username, isAdmin)
                .then(response => {
                    dispatchResponseReceivedEvent("success", "Utente " + response.username + " creato con successo!\nPassword: " + response.password);
                    dispatchUserCreatedEvent();
                    resetForm();
                })
                .catch(error => {
                    console.log(error);
                    dispatchResponseReceivedEvent("error", error.body?.detail);
                });
        } else {
            console.log("Please fill in all the required fields.");
        }
    }

    function validateForm() {
        return isUsernameValid()
    }

    function resetForm() {
        setIsFormSubmitted(false);
        setUsername("");
        setIsAdmin(false);
    }

    function isUsernameValid() {return username.trim() !== "";}

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value);

    const ToggleSwitchTheme: CustomFlowbiteTheme['toggleSwitch'] = {
        root: {
            base: "group relative flex items-center rounded-lg",
            active: {
                on: "cursor-pointer",
                off: "cursor-not-allowed opacity-50"
            },
            label: "ml-3 text-sm font-medium text-gray-900"
        },
        toggle: {
            base: "toggle-bg rounded-full border",
            checked: {
                on: "after:translate-x-full after:border-white",
                off: "border-gray-200 bg-gray-200",
                color: {
                    info: "bg-tertiary border-tertiary"
                }
            }
        }
    }

    return (

        <form className="flex-col" onSubmit={handleSubmit}>

            <div className="max-w-md w-full mb-4">
                <div className="mb-1 block">
                    <Label htmlFor="username" value="Inserisci lo username"/>
                </div>
                <TextInput id="username" type="text" sizing="md" value={username}
                           color={isFormSubmitted && !isUsernameValid() ? "failure" : ""}
                           onChange={handleUsernameChange}/>
            </div>

            <div className="max-w-md w-full mb-4 gap-2">
                <div className="flex h-5 items-center">
                    <ToggleSwitch theme={ToggleSwitchTheme} color="info" checked={isAdmin} label="Admin user" onChange={setIsAdmin} />
                </div>
                <div className="flex flex-col ml-14">
                    <div className="text-gray-500">
                        <span className="text-xs font-normal">
                            Un admin user può eseguire operazioni sugli utenti.
                        </span>
                    </div>
                </div>
            </div>

            <Button type="submit"
                    size="md"
                    className="max-w-md w-full text-primary bg-secondary hover:text-opacity-70 hover:bg-opacity-70 shadow-md">
                Crea Utente
            </Button>

        </form>
    )
}

export default CreateUserForm;
