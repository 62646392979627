
export type ResponseMessage = {
    type: 'success' | 'error';
    message: string;
};

export const dispatchResponseReceivedEvent = (type: "success" | "error", message: string | null | undefined) => {
    let defaultMessage = type === "success" ? "Operation completed successfully" : "An error occurred";
    const response: ResponseMessage = {
        type: type,
        message: message || defaultMessage
    };
    const event = new CustomEvent('responseReceived', {detail: response});
    document.dispatchEvent(event);
};

export const dispatchOfferUpdatedEvent = () => {
    const offerUpdatedEvent = new Event('offerUpdated');
    document.dispatchEvent(offerUpdatedEvent);
}

export const dispatchOfferCreatedEvent = () => {
    const offerUpdatedEvent = new Event('offerCreated');
    document.dispatchEvent(offerUpdatedEvent);
}

export const dispatchUserAuthenticatedEvent = () => {
    const userAuthenticatedEvent = new Event('userAuthenticated');
    document.dispatchEvent(userAuthenticatedEvent);
}

export const dispatchUserCreatedEvent = () => {
    const userCreatedEvent = new Event('userCreated');
    document.dispatchEvent(userCreatedEvent);
}
