import {Button, FileInput, Label, Select, Textarea, TextInput} from "flowbite-react";
import React, {FormEvent, useState} from "react";
import {AdminService, Company, DBOffer, type Offer, ImageFile} from "../api";
import {dispatchOfferCreatedEvent, dispatchOfferUpdatedEvent, dispatchResponseReceivedEvent} from "../events/events";


interface OfferFormProps {
    offerToUpdate?: DBOffer;
}

export function OfferForm({offerToUpdate}: OfferFormProps) {
    const [isUpdateTemplate, setIsUpdateTemplate] = useState(offerToUpdate != null);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [offerType, setOfferType] = useState((offerToUpdate == null) ? "" : offerToUpdate.offer_type);
    const [destination, setDestination] = useState((offerToUpdate == null) ? "" : offerToUpdate.location);
    const [name, setName] = useState((offerToUpdate == null) ? "" : offerToUpdate.name);
    const [description, setDescription] = useState((offerToUpdate == null  || offerToUpdate.description == null) ? "" : offerToUpdate.description);
    const [nightStay, setNightStay] = useState((offerToUpdate == null) ? 0 : offerToUpdate.night_stay);
    const [company, setCompany] = useState((offerToUpdate == null || offerToUpdate.company == null) ? "" : offerToUpdate.company.toString());
    const [basePrice, setBasePrice] = useState((offerToUpdate == null) ? 0 : offerToUpdate.price);
    const [memberPrice, setMemberPrice] = useState((offerToUpdate == null) ? 0 : offerToUpdate.member_price);
    const [currency, setCurrency] = useState((offerToUpdate == null) ? "" : offerToUpdate.currency);
    const [image, setImage] = useState<ImageFile | null>(null);

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setIsFormSubmitted(true);

        if (validateForm()) {
            if (isUpdateTemplate && offerToUpdate && offerToUpdate._id) {
                const updatedOffer = updateOffer(offerToUpdate._id);
                console.log(updatedOffer);

                if (updatedOffer) {
                    AdminService.updateOffer(updatedOffer)
                        .then(response => {
                            console.log(response);
                            dispatchResponseReceivedEvent("success", response.message);
                            dispatchOfferUpdatedEvent();
                        })
                        .catch(error => {
                            console.log(error);
                            dispatchResponseReceivedEvent("error", error.body?.detail);
                        });
                }
            } else {
                const newOffer = initOffer();
                console.log(newOffer);

                if (newOffer) {
                    AdminService.createOffer(newOffer)
                        .then(response => {
                            console.log(response);
                            dispatchResponseReceivedEvent("success", response.message);
                            dispatchOfferCreatedEvent();
                            resetForm();
                        })
                        .catch(error => {
                            console.log(error);
                            dispatchResponseReceivedEvent("error", error.body?.detail);
                        });
                }
            }
        } else {
            console.log("Please fill in all the required fields.");
        }
    }

    function initOffer() {
        const newOffer: Offer = {
            offer_type: offerType,
            image: image || null,
            night_stay: nightStay,
            name: name,
            description: description !== "" ? description : null,
            location: destination,
            company: company as Company || null,
            price: basePrice,
            member_price: memberPrice,
            currency: currency,
        };

        return newOffer;
    }

    function updateOffer(id: string) {
        const updatedOffer: DBOffer = {
            _id: id,
            offer_type: offerType,
            image: image || null,
            night_stay: nightStay,
            name: name,
            description: description !== "" ? description : null,
            location: destination,
            company: company as Company || null,
            price: basePrice,
            member_price: memberPrice,
            currency: currency,
        };
        return updatedOffer;
    }

    function validateForm() {

        return isOfferTypeValid() &&
            isDestinationValid() &&
            isNameValid() &&
            isDescriptionValid() &&
            isNightStayValid() &&
            isCompanyValid() &&
            isBasePriceValid() &&
            isMemberPriceValid() &&
            isCurrencyValid() &&
            isImageValid();
    }

    function resetForm() {
        setIsUpdateTemplate(offerToUpdate != null);
        setIsFormSubmitted(offerToUpdate != null);

        setOfferType((offerToUpdate == null) ? "" : offerToUpdate.offer_type);
        setDestination((offerToUpdate == null) ? "" : offerToUpdate.location);
        setName((offerToUpdate == null) ? "" : offerToUpdate.name);
        setDescription((offerToUpdate == null || offerToUpdate.description == null) ? "" : offerToUpdate.description);
        setNightStay((offerToUpdate == null) ? 0 : offerToUpdate.night_stay);
        setCompany((offerToUpdate == null || offerToUpdate.company == null) ? "" : offerToUpdate.company.toString());
        setBasePrice((offerToUpdate == null) ? 0 : offerToUpdate.price);
        setMemberPrice((offerToUpdate == null) ? 0 : offerToUpdate.member_price);
        setCurrency((offerToUpdate == null) ? "" : offerToUpdate.currency);
        setImage(null);
        resetFileInput();
        setError(null)
    }

    function resetFileInput() {
        const fileInput = document.getElementById('image') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = "";
        }
    }

    const handleOfferTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => setOfferType(event.target.value);
    const handleDestinationChange = (event: React.ChangeEvent<HTMLInputElement>) => setDestination(event.target.value);
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(event.target.value);
    const handleNightStayChange = (event: React.ChangeEvent<HTMLInputElement>) => setNightStay(event.target.valueAsNumber || 0);
    const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => setCompany(event.target.value);
    const handleBasePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => setBasePrice(event.target.valueAsNumber || 0);
    const handleMemberPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => setMemberPrice(event.target.valueAsNumber || 0);
    const handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => setCurrency(event.target.value);
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                try {
                    // Convert ArrayBuffer to base64
                    const arrayBuffer = reader.result as ArrayBuffer;
                    const uint8Array = new Uint8Array(arrayBuffer);
                    const encodedData = btoa(String.fromCharCode.apply(null, Array.from(uint8Array)));

                    const imageFile: ImageFile = {
                        filename: file.name,
                        encoded_data: `data:${file.type};base64,${encodedData}`
                    };

                    // Set your state or perform further actions with the imageFile
                    setImage(imageFile);
                    setError(null);
                }
                catch (e) {
                    console.log(e);
                    setError("Immagine troppo grande. Dimensione massima consentita: 105 KB");
                }
            };

            reader.readAsArrayBuffer(file);
        } else {
            setError(null);
        }
    };

    function isOfferTypeValid() {return offerType.trim() !== "";}
    function isDestinationValid() {return destination.trim() !== "";}
    function isNameValid() {return name.trim() !== "";}
    function isDescriptionValid() {return description.length <= 340}
    function isNightStayValid() {return nightStay >= 1;}
    function isCompanyValid() {return company.trim() !== "";}
    function isBasePriceValid() {return basePrice >= 1;}
    function isMemberPriceValid() {return memberPrice >= 1;}
    function isCurrencyValid() {return currency.trim() !== "";}
    function isImageValid() {return !error;}

    return (

        <form className="flex flex-col w-2/3 m-auto" onSubmit={handleSubmit}>

            {/**********************GENERAL INFO**********************/}
            <div className="flex items-center space-x-2 max-w-lg w-full m-auto mt-4">
                <span className="font-semibold text-lg text-secondary flex-shrink-0">
                                Informazioni Generali
                </span>
                <div className="border-t-2 border-secondary w-full"/>
            </div>
            {/*Offer Type*/}
            <div className="max-w-md w-full mb-2 m-auto">
                <div className="mb-1 block">
                    <Label htmlFor="offerType" value="Seleziona la categoria"/>
                </div>
                <Select id="offerType" value={offerType}
                        color={isFormSubmitted && !isOfferTypeValid() ? "failure" : ""}
                        onChange={handleOfferTypeChange}
                        sizing="sm">
                    <option>-</option>
                    <option>Crociera</option>
                    <option>Tour</option>
                    <option>Trade & Travel</option>
                </Select>
            </div>
            {/*Location*/}
            <div className="max-w-md w-full mb-2 m-auto">
                <div className="mb-1 block">
                    <Label htmlFor="destination" value="Inserisci la destinazione"/>
                </div>
                <TextInput id="destination" type="text" sizing="sm" value={destination}
                           color={isFormSubmitted && !isDestinationValid() ? "failure" : ""}
                           onChange={handleDestinationChange}/>
            </div>
            {/*Title*/}
            <div className="max-w-md w-full mb-2 m-auto">
                <div className="mb-1 block">
                    <Label htmlFor="name" value="Inserisci il titolo/nome"/>
                </div>
                <TextInput id="name" type="text" sizing="sm" value={name}
                           color={isFormSubmitted && !isNameValid() ? "failure" : ""}
                           onChange={handleNameChange}/>
            </div>
            {/*Description*/}
            <div className="max-w-md w-full mb-2 m-auto">
                <div className="mb-1 block">
                    <Label htmlFor="description" value="Inserisci la descrizione (opzionale)"/>
                </div>
                <Textarea id="description" placeholder="Descrizione..." rows={3} value={description}
                          className="text-xs"
                          helperText={<p className="text-xs">
                                Lunghezza massima: 340 caratteri
                          </p>}
                          color={isFormSubmitted && !isDescriptionValid() ? "failure" : ""}
                          onChange={handleDescriptionChange}/>
            </div>

            {/************************DETAILS************************/}
            <div className="flex items-center space-x-2 max-w-lg w-full m-auto mt-4">
                            <span className="font-semibold text-lg text-secondary flex-shrink-0">
                                Dettagli Offerta
                            </span>
                <div className="border-t-2 border-secondary w-full"/>
            </div>
            {/*Night Stay*/}
            <div className="max-w-md w-full mb-2 m-auto">
                <div className="mb-1 block">
                    <Label htmlFor="nightStay" value="Inserisci il numero di notti"/>
                </div>
                <TextInput id="nightStay" type="number" sizing="sm" value={nightStay}
                           color={isFormSubmitted && !isNightStayValid() ? "failure" : ""}
                           onChange={handleNightStayChange}/>
            </div>
            {/*Company*/}
            <div className="max-w-md w-full mb-2 m-auto">
                <div className="mb-1 block">
                    <Label htmlFor="company" value="Seleziona la compagnia"/>
                </div>
                <Select id="company" sizing="sm" value={company}
                        color={isFormSubmitted && !isCompanyValid() ? "failure" : ""}
                        onChange={handleCompanyChange}>
                    <option>-</option>
                    <option value={Company.AZAMARA}>Azamara</option>
                    <option value={Company.CARNIVAL}>Carnival</option>
                    <option value={Company.CELEBRITY}>Celebrity</option>
                    <option value={Company.CUNARD}>Cunard</option>
                    <option value={Company.EMERALD}>Emerald</option>
                    <option value={Company.HOLLAND_AMERICA}>Holland America</option>
                    <option value={Company.HURTIGRUTEN}>Hurtigruten</option>
                    <option value={Company.MSC}>MSC</option>
                    <option value={Company.NORWEGIAN}>Norwegian</option>
                    <option value={Company.OCEANIA}>Oceania</option>
                    <option value={Company.ROYAL_CARIBBEAN}>Royal Caribbean</option>
                    <option value={Company.SCENIC}>Scenic</option>
                    <option value={Company.UNIWORLD}>Uniworld</option>
                    <option value={Company.WINDSTAR}>Windstar</option>
                    <option value={Company.ALTRO}>Altro</option>
                </Select>
            </div>
            <div className="flex justify-between max-w-md w-full mb-2 m-auto">
                {/*Price*/}
                <div className="w-1/3">
                    <div className="mb-1 block">
                        <Label htmlFor="basePrice" value="Prezzo base"/>
                    </div>
                    <TextInput id="basePrice" type="number" sizing="sm" value={basePrice}
                               color={isFormSubmitted && !isBasePriceValid() ? "failure" : ""}
                               onChange={handleBasePriceChange}/>
                </div>
                {/*Member Price*/}
                <div className="w-1/3">
                    <div className="mb-1 block">
                        <Label htmlFor="memberPrice" value="Prezzo soci"/>
                    </div>
                    <TextInput id="memberPrice" type="number" sizing="sm" value={memberPrice}
                               color={isFormSubmitted && !isMemberPriceValid() ? "failure" : ""}
                               onChange={handleMemberPriceChange}/>
                </div>
                {/*Currency*/}
                <div className="w-1/5">
                    <div className="mb-1 block">
                        <Label htmlFor="currency" value="Valuta"/>
                    </div>
                    <Select id="currency" sizing="sm" value={currency}
                            color={isFormSubmitted && !isCurrencyValid() ? "failure" : ""}
                            onChange={handleCurrencyChange}>
                        <option>-</option>
                        <option value="EUR">€</option>
                        <option value="USD">$</option>
                        <option value="GBP">£</option>
                    </Select>
                </div>
            </div>

            {/************************MEDIAS************************/}
            <div className="flex items-center space-x-2 max-w-lg w-full m-auto mt-4">
                            <span className="font-semibold text-lg text-secondary flex-shrink-0">
                                Risorse
                            </span>
                <div className="border-t-2 border-secondary w-full"/>
            </div>
            {/*Image*/}
            <div id="fileUpload" className="max-w-md w-full mb-2 m-auto">
                <div className="mb-1 block">
                    <Label htmlFor="image" value="Seleziona un'immagine da mostrare nella preview (opzionale)"/>
                </div>
                <FileInput id="image"
                           helperText={error || "Formati supportati: .png .jpeg"}
                           accept=".png,.jpeg,.jpg"
                           sizing="sm"
                           color={!isImageValid() ? "failure" : ""}
                           onChange={handleImageChange}/>
            </div>

            <Button type="submit"
                    size="xl"
                    className="flex justify-center mx-auto my-4 h-10 w-fit px-4 text-primary bg-secondary hover:text-opacity-70 hover:bg-opacity-70 shadow-md">
                {isUpdateTemplate ? "Aggiorna Offerta" : "Crea Offerta"}
            </Button>
        </form>
    )
}

export default OfferForm;
