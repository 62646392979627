import React, {FormEvent, useState} from "react";
import {AdminService, Body_authenticate} from "../../../api";
import {Button, type CustomFlowbiteTheme, Label, TextInput} from "flowbite-react";
import {dispatchUserAuthenticatedEvent} from "../../../events/events";

function Login() {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setIsFormSubmitted(true);

        if (validateForm()) {
            const auth_data: Body_authenticate = {username: username, password: password}
            AdminService.authenticate(auth_data)
                .then(response => {
                    sessionStorage.setItem('token', response.access_token);
                    sessionStorage.setItem('username', response.username);
                    sessionStorage.setItem('admin', response.admin.toString());
                    dispatchUserAuthenticatedEvent()
                    resetForm();
                })
                .catch(error => {
                    setError(error.body?.detail || "An error occurred.");
                });
        } else {
            setError("Please fill in all the required fields.");
        }
    }

    function validateForm() {
        return isUsernameValid() && isPasswordValid();

    }

    function resetForm() {
        setIsFormSubmitted(false);
        setUsername("");
        setPassword("");
    }

    function isUsernameValid() {return username.trim() !== "";}
    function isPasswordValid() {return password.trim() !== "";}


    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value);
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

    const customLabelTheme: CustomFlowbiteTheme['label'] = {
        root: {
            colors: {
                default: "text-tertiary",
            }
        }
    }

    return (
        <div className="flex flex-col items-center justify-center w-full h-screen bg-primary">
            <div className="flex justify-center m-auto">
                <img src="/7Across-logo.png" className="h-20 sm:h-20" alt="Flowbite React Logo"/>
            </div>

            <div className="flex flex-col justify-center w-full m-auto gap-y-4">
                <div className="flex justify-center">
                    <span className="text-2xl font-bold text-white">Admin Panel</span>
                </div>

                <form className="flex flex-col w-2/3 m-auto" onSubmit={handleSubmit}>

                    <div className="max-w-md w-full m-auto text-center text-red-500">{error || '\u00A0'}</div>

                    <div className="max-w-md w-full mb-2 m-auto">
                        <div className="mb-1 block">
                            <Label htmlFor="username" value="Username" theme={customLabelTheme}/>
                        </div>
                        <TextInput id="username" type="text" sizing="md" value={username}
                                   color={isFormSubmitted && !isUsernameValid() ? "failure" : ""}
                                   onChange={handleUsernameChange}/>
                    </div>

                    <div className="max-w-md w-full mb-2 m-auto">
                        <div className="mb-1 block">
                            <Label htmlFor="password" value="Password" theme={customLabelTheme}/>
                        </div>
                        <TextInput id="password" type="password" sizing="md" value={password}
                                   color={isFormSubmitted && !isPasswordValid() ? "failure" : ""}
                                   onChange={handlePasswordChange}/>
                    </div>

                    <Button type="submit"
                            size="md"
                            className="flex justify-center max-w-md w-full mt-6 mx-auto text-primary bg-secondary hover:text-opacity-70 hover:bg-opacity-70 shadow-md">
                        Login
                    </Button>
                </form>
            </div>
        </div>
    )
        ;
}

export default Login;
