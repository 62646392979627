
export function getAuthHeader() {
    let authHeader: { Authorization: string } | null = null;

    // Check if the code is running in a browser environment
    if (typeof window !== 'undefined') {
        const token = sessionStorage.getItem('token');

        if (token) {
            authHeader = { Authorization: `Bearer ${token}` };
        }
    }

    return authHeader;
}

export function getUsername() {
    let username: string | null = null;

    // Check if the code is running in a browser environment
    if (typeof window !== 'undefined') {
        username = sessionStorage.getItem('username');
    }

    return username;
}

export function hasAdminPermission() {
    let adminPermission: boolean | null = null;

    // Check if the code is running in a browser environment
    if (typeof window !== 'undefined') {
        adminPermission = sessionStorage.getItem('admin') === 'true';
    }

    return adminPermission;
}
