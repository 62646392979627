import {Button} from 'flowbite-react';
import React from "react";
import {useNavigate} from "react-router-dom";

interface CustomCardProps {
    imgSrc: string;
    imgAlt: string;
    title: string;
    description: string;
    buttonLabel: string;
    buttonLink: string | null;
}

function CustomCard({imgSrc, imgAlt, title, description, buttonLabel, buttonLink} : CustomCardProps) {

    const navigate = useNavigate();

    function navigateToUrl() {
        if (buttonLink)
            navigate(buttonLink);
    }

    return (
        <div className="flex flex-col w-full h-full bg-white border border-gray-200 rounded-lg shadow">
            <img src={imgSrc} alt={imgAlt} className="rounded-t-lg h-2/5 object-cover"/>
            <div className="py-4 px-8 w-full h-2/5">
                <h5 className="flex justify-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {title}
                </h5>
                <p className="flex text-center font-normal text-gray-700 dark:text-gray-400">
                    {description}
                </p>
            </div>

            <div className="flex justify-center mt-auto py-4">
                <Button onClick={navigateToUrl} size="xl"
                        className="h-10 text-black bg-secondary hover:text-opacity-70 hover:bg-opacity-70 shadow-md">
                    {buttonLabel}
                </Button>
            </div>
        </div>
    );
}

export default CustomCard;