import React, {useEffect, useState} from 'react';
import CustomOfferCard from "../../components/CustomOfferCard";
import {DBOffer, OffersService} from "../../api";
import {Alert, CustomFlowbiteTheme, Label, Select} from "flowbite-react";
import {HiInformationCircle, HiOutlineFilter, HiOutlineSortDescending} from "react-icons/hi";
import './Offer.css';

function Offer() {

    const [offers, setOffers] = useState<Array<DBOffer | null>>([]);
    const [selectedOffers, setSelectedOffers] = useState<Array<DBOffer | null>>([]);
    const [uniqueDestinations, setUniqueDestinations] = useState<Array<string>>([]);
    const [filterValue, setFilterValue] = useState<string>("all");
    const [sortValue, setSortValue] = useState<string>("");

    useEffect(() => {
        fetchOffers().then();
    }, []);

    const fetchOffers = async () => {
        try {
            const response = await OffersService.getOffersByType("Offer");
            setOffers(response);
            setSelectedOffers(response)
            setUniqueDestinations([...new Set(response.map(offer => offer.location))]);
        } catch (error) {
            console.error("Error fetching offers:", error);
        }
    };

    useEffect(() => {
        handleFilterSortChange();
    }, [filterValue, sortValue]);

    const handleFilterSortChange = () => {
        filterAndSortOffers(filterValue, sortValue);
    };

    function filterAndSortOffers(searchValue: string, sortValue: string) {
        let filteredAndSortedOffers = [...offers];

        // Filtering
        if (searchValue !== "all") {
            filteredAndSortedOffers = filteredAndSortedOffers.filter(offer =>
                offer?.location.toLowerCase().includes(searchValue.toLowerCase())
            );
        }

        // Sorting
        if (sortValue === "name") {
            filteredAndSortedOffers.sort((a, b) =>
                (a != undefined && b != undefined) && (a?.name > b?.name) ? 1 : -1);

        } else if (sortValue === "price") {
            filteredAndSortedOffers.sort((a, b) =>
                (a != undefined && b != undefined) && (a?.price > b?.price) ? 1 : -1);

        } else if (sortValue === "saving") {
            filteredAndSortedOffers.sort((a, b) =>
                (a != undefined && b != undefined) && ((a?.price - a?.member_price) < (b?.price - b?.member_price)) ? 1 : -1);
        }

        setSelectedOffers(filteredAndSortedOffers);
    }


    const handleFilterValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(event.target.value)
    };

    const handleSortvalue = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortValue(event.target.value)
    };


    const CustomNoOffersAlertTheme: CustomFlowbiteTheme['alert'] = {
        color: {
            info: "text-primary bg-blue-100 border-primary",
        },
        wrapper: "flex items-center justify-center"
    }

    return (
        <div>
            <div className="relative">
                <img className="h-96 w-full object-cover" src="/images/offer/offer_header.jpg" alt="offer_header"/>
                <span
                    className="absolute inset-0 flex items-center justify-center font-bold text-6xl border-text">Offerte</span>
            </div>

            <div className="bg-tertiary text-center p-4">
                <p className="text-lg font-medium px-4">
                    Visita posti nuovi, conosci gente interessante e assaggia cibi locali.
                    Prenota a prezzi scontati in strutture in tutto il mondo!
                </p>
            </div>

            <div className="flex justify-center m-8">
                <h5 className="text-2xl font-bold">Scorri per visualizzare le migliori offerte!</h5>
            </div>

            {offers && offers.length > 0
                ?
                <div>
                    <div className="flex justify-center mx-auto gap-x-8 my-8">
                        <div className="w-1/4">
                            <div className="mb-2 block">
                                <Label htmlFor="filter" value="Filtra offerte per destinazione"/>
                            </div>
                            <Select id="filter"
                                    icon={HiOutlineFilter}
                                    onChange={handleFilterValue}
                                    required>
                                <option value={"all"}>Tutte</option>
                                {uniqueDestinations && uniqueDestinations.map((destination, index) => (
                                    <option key={index} value={destination}>{destination}</option>
                                ))}
                            </Select>
                        </div>

                        <div className="w-1/4">
                            <div className="mb-2 block">
                                <Label htmlFor="filter" value="Ordina per"/>
                            </div>
                            <Select id="filter"
                                    icon={HiOutlineSortDescending}
                                    onChange={handleSortvalue}
                                    required>
                                <option value={""}>-</option>
                                <option value={"name"}>Nome</option>
                                <option value={"price"}>Prezzo piu economico</option>
                                <option value={"saving"}>Risparmio maggiore</option>
                            </Select>
                        </div>
                    </div>

                    <div className="justify-center my-8 mx-12 gap-4 grid
                                  2xl:grid-cols-4
                                  xl:grid-cols-3
                                  lg:grid-cols-3
                                  md:grid-cols-2
                                  sm:grid-cols-1">
                        {selectedOffers.map((offer, index) => (
                            <div key={index} className="h-screen">
                                {offer &&
                                    <CustomOfferCard
                                        image={offer.image ? offer.image : null}
                                        night_stay={offer.night_stay}
                                        title={offer.name}
                                        location={offer.location}
                                        company={offer.company ? offer.company : null}
                                        price={offer.price}
                                        member_price={offer.member_price}
                                        currency={offer.currency}
                                        description={offer.description ? offer.description : null}/>
                                }
                            </div>
                        ))}
                    </div>
                </div>
                :
                <Alert
                    className="w-fit my-8 mx-auto"
                    theme={CustomNoOffersAlertTheme}
                    color="info"
                    icon={HiInformationCircle}
                    rounded>
                    <span className="text-base text-center">Al momento non sono presenti offerte per questa categoria.</span>
                </Alert>
            }
        </div>
    );
}

export default Offer;
