/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * enum for company names
 */
export enum Company {
    AZAMARA = 'Azamara',
    CARNIVAL = 'Carnival',
    CELEBRITY = 'Celebrity',
    CUNARD = 'Cunard',
    EMERALD = 'Emerald',
    HOLLAND_AMERICA = 'Holland America',
    HURTIGRUTEN = 'Hurtigruten',
    MSC = 'MSC',
    NORWEGIAN = 'Norwegian',
    OCEANIA = 'Oceania',
    ROYAL_CARIBBEAN = 'Royal Caribbean',
    SCENIC = 'Scenic',
    UNIWORLD = 'Uniworld',
    WINDSTAR = 'Windstar',
    ALTRO = 'Altro',
}
