import {Navbar} from "flowbite-react";
import React from "react";

function CustomNavBar(){
    return (
        <Navbar className="bg-primary p-4">
            <Navbar.Brand href="/" className="mx-5">
                <img src="/7Across-logo.png" className="h-14 sm:h-14" alt="Flowbite React Logo"/>
            </Navbar.Brand>
            <Navbar.Toggle/>
            <Navbar.Collapse>
                <Navbar.Link href="/" className="text-lg text-white mx-5 hover:text-secondary">Home</Navbar.Link>
                <Navbar.Link href="/cruise" className="text-lg text-white mx-5 hover:text-secondary">Crociere</Navbar.Link>
                <Navbar.Link href="/offer" className="text-lg text-white mx-5 hover:text-secondary">Offerte</Navbar.Link>
                <Navbar.Link href="/trade-travel" className="text-lg text-white mx-5 hover:text-secondary">Trade + Travel</Navbar.Link>
                <Navbar.Link href="/contacts" className="text-lg text-white mx-5 hover:text-secondary">Contatti</Navbar.Link>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default CustomNavBar;
