import React from 'react';
import {List} from "flowbite-react";

function TermsAndConditions() {
    return (
        <div className="m-8 min-h-screen">
            <h1 className="my-4 font-bold flex justify-center">TERMINI E CONDIZIONI DI UTILIZZO DEI SERVIZI TOP DESTINATIONS</h1>

            <List className="text-black" ordered>
                I seguenti termini e condizioni ("Condizioni Generali") regolano l'accesso e l'utilizzo da parte
                dell'utente del sito internet www.7acrossitalia.com e www.7acrossitalia.it (“Sito/i”) dei servizi
                offerti da Top Destinations Agenzia Viaggi ("Servizi").
                <List.Item>DESCRIZIONE DEL SERVIZIO</List.Item>
                Top Destinations Agenzia Viaggi è un marchio di Top Vacation Srls, una società di diritto italiano una
                società costituita in Italia con P.IVA  04218240234, la cui sede legale è a Verona, in V.lo Dietro
                Caserma Chiodo 22. L'utente è una persona fisica, che dichiara di essere maggiorenne e, pertanto, di
                avere la capacità giuridica per acquistare i servizi offerti tramite il Sito e utilizzare quest'ultimo
                in conformità con le Condizioni Generali ("Utente").
                L'Utente dichiara, altresì, che tutte le informazioni da lui fornite nella fase di registrazione al
                Sito Internet e durante il suo utilizzo sono vere, complete, accurate e aggiornate, assumendosi ogni
                responsabilità in caso contrario. A tali fini, l'Utente si impegna a comunicare tempestivamente a Top
                Destinations ogni modifica dei propri dati di registrazione. Top Destinations gestisce il Sito offrendo
                i servizi turistici in esso contenuti. L'acquisto di servizi tramite il Sito comporta l'accettazione
                da parte dell'Utente delle presenti Condizioni Generali, disponibili sul Sito, oltre ad eventuali
                Termini e Condizioni Particolari di Contratto visualizzate sul Sito durante il processo di
                prenotazione o comunicate via mail all’indirizzo dell’Utente da parte di Top Destinations.
                In particolare, nel caso delle crociere, l'utilizzo da parte del cliente del Sito per localizzare
                l’offerta ai fini della loro contrattazione implica l'assoggettamento diretto del cliente alle
                condizioni generali di dette compagnie di crociera e alle condizioni generali dei siti web specifici
                di ciascuna di esse, comprese quelle corrispondenti alla protezione dei dati personali.
                <List.Item>UTILIZZO DEL SITO INTERNET</List.Item>
                Il Sito è solo ed esclusivamente per uso personale dell'Utente e non può essere utilizzato per scopi illeciti. In particolare, l'Utente non può rivendere a terzi i prodotti o i servizi acquistati attraverso il Sito Internet.Per usufruire dei servizi offerti dal Sito Internet, e in particolare per trasmettere ordini di acquisto, è necessario registrarsi.
                L'utilizzo del Sito Internet da parte dell'Utente comporta l'accettazione, espressa, piena e senza riserve, delle presenti Condizioni Generali, in particolare quelle stabilite in merito alla limitazione di responsabilità di Top Destinations e dei fornitori di servizi turistici o di viaggio.
                Prima di ogni utilizzo del Sito, l'Utente ha, dunque, l'obbligo di leggere attentamente le Condizioni Generali in ciascuna delle occasioni in cui intenda utilizzare il Sito, dato che le stesse possono essere state modificate dal suo ultimo accesso.
                In caso di mancato rispetto delle Condizioni Generali, Top Destinations si riserva il diritto di negare o limitare all'Utente trasgressore l'accesso al Sito e/o ai servizi ivi forniti, in qualunque momento e senza necessità di previo avviso.
                <List.Item>CONCLUSIONE DEL CONTRATTO</List.Item>
                Ogni qual volta l’Utente contatterà o trasmetterà a Top Destinations una richiesta di acquisto di un Servizio, riceverà da parte di Top Destinations stessa una mail con cui saranno date informazioni ed eventualmente offerte relativamente alla richiesta. Resta inteso che né tale richiesta né la successiva mail di informazioni/offerte definiscono l'acquisto del Servizio scelto. Solo a seguito di una conferma di interesse per l’offerta ricevuta, Top Destinations procederà alla verifica ed eventualmente opzione della disponibilità di posti. L'acquisto di qualsiasi Servizio si completerà solo nel momento in cui Top Destinations riceverà validamente mediante bonifico l'importo relativo all'acquisto. A seguito della conclusione del contratto, l'eventuale richiesta di cancellazione potrebbe comportare una penale commisurata al tipo di prodotto richiesto e variabile in base alla politica di cancellazione applicata dal fornitore dei servizi richiesti. A tale proposito si invita l'Utente a consultare anche le condizioni specifiche dei fornitori. In caso di sia effettuata un'unica prenotazione per più soggetti, l'Utente che effettua la prenotazione garantisce di avere la necessaria autorizzazione nonché il rispetto di tutti gli obblighi contrattuali anche da parte degli altri soggetti indicati nella prenotazione.
                <List.Item>COMUNICAZIONI</List.Item>
                L’Utente che dovesse avere un reclamo riguardante il Servizio dovrà immediatamente consultarsi con Top Destinations, contattando telefonicamente il numero 045 2588694 oppure scrivendo all’indirizzo info@topdestinations.it. TTop Destinations declina qualsiasi responsabilità nei confronti degli Utenti o dei loro Ospiti per qualsiasi costo e spesa diversi da quelli indicati all’atto della prenotazione, sostenuti durante la fruizione del Servizio qualora gli Utenti o i loro Ospiti avessero sostenuto tali spese senza aver ottenuto autorizzazione da Top Destinations.
                <List.Item>ISCRIZIONE/REGISTRAZIONE</List.Item>
                Nell'accedere al Sito, l'Utente accetta che i propri dati personali ("Dati") siano sottoposti al trattamento elettronico in archivi di proprietà di Top Destinations. Top Destinations informa l'Utente che i Dati saranno trattati confidenzialmente e archiviati in server che adempiono i requisiti stabiliti dalla legislazione italiana in materia, come meglio chiarito nell’ INFORMATIVA SULLA PRIVACY consultabile sul Sito. Si precisa sin d'ora che il conferimento dei Dati per tali finalità è obbligatorio e l'eventuale rifiuto comporterà l'impossibilità ad instaurare rapporti contrattuali. Il trattamento dei Dati non richiede il consenso dell'interessato. L'Utente autorizza Top Destinations a utilizzare i Dati per la presentazione e amministrazione dei  servizi e delle comunicazioni attraverso diversi canali: e-mail, telefono o sistemi cellulari nelle loro diverse opzioni.
                <List.Item>PREZZI, MODIFICHE, CANCELLAZIONI
                    I prezzi indicati sul Sito sono relativi ad una specifica proposta, validi alla data di pubblicazione e possono subire variazioni. L'importo effettivo ed aggiornato che pagherà l'Utente sarà dettagliato nelle comunicazioni via mail tra l’Utente e Top Destinations. I prodotti prenotati e acquistati sul Sito  non potranno essere cambiati o rimborsati, fatta salva l'applicazione della legge sul diritto di recesso del consumatore (D.Lgs 206/2005) quando applicabile, e la specifica politica di cancellazione e/o modificazione applicata ai seguenti prodotti:
                    <List className="text-black" nested>
                        <List.Item>Cancellazioni e/o modifiche vacanze</List.Item>
                        In caso l’Utente decidesse di cancellare una prenotazione in una struttura offerta  in affitto scontato da Top Destinations, si applicheranno le seguenti penali: tra 45 e 31 giorni dalla data partenza 30% della quota di prenotazione. A 30 giorni o meno dalla data di partenza 100% della quota di prenotazione;
                        <List.Item>Cancellazioni e/o modifiche crociere</List.Item>
                        In caso l’Utente decidesse di cancellare e/o modificare la prenotazione di un prodotto crociera, Top Destinations verificherà per ciascun fornitore le condizioni e le penali di cancellazione e/o modificazione della prenotazione (date, destinazioni) applicate dallo stesso. Top Destinations inoltre applicherà un costo di gestione della pratica pari al 5% del totale per la cancellazione e/o modificazione che si sommerà alle penali eventualmente applicate dai fornitori del prodotto;
                        <List.Item>Cambio nome</List.Item>
                        I biglietti sono documenti nominali e non trasferibili a terzi; il cambio nome pertanto può essere richiesto esclusivamente per le prenotazioni di affitti scontati o hotel, salvo diversa indicazione del fornitore del servizio.
                    </List>
                    La notifica di cancellazione avrà effetto solo quando sarà stata da ricevuta da Top Destinations via mail all’indirizzo info@topdestinations.it e la cancellazione confermata al cliente per iscritto.  Si consiglia vivamente di sottoscrivere un'assicurazione di viaggio completa.
                </List.Item>
                <List.Item>DIRITTI RISERVATI / DIVIETO DI CESSIONE O SFRUTTAMENTO</List.Item>
                Il Sito  e ogni suo contenuto  sono di esclusiva titolarità di Top Destinations e ne è vietata la distribuzione, la comunicazione pubblica e la trasformazione. L'Utente si impegna a utilizzare il Sito solo ed esclusivamente per uso privato e personale.
                <List.Item>RESPONSABILITÀ DELL'UTENTE</List.Item>
                E' responsabilità dell'Utente essere in possesso di un documento d'identità valido (carta identità o passaporto oltre all'eventuale visto, laddove richiesto) così come aver espletato ogni formalità richiesta per il viaggio (quale, ad esempio, la vaccinazione o diverso certificato medico), sia esso relativo ad un Pacchetto Turistico, sia a singoli Servizi di Viaggio. In proposito si ricorda che le necessarie informazioni sono disponibili sulla pagina web del Ministero degli Affari Esteri ( http://www.viaggiaresicuri.it/) nonché presso le competenti Autorità dei Paesi di destinazione. È obbligo dell'Utente informarsi presso queste ultime in merito ad eventuali procedure particolari relative al viaggio.
                <List.Item>ESONERO E LIMITI DI RESPONSABILITÀ</List.Item>
                Il Sito è fornito da Top Destinations "così com'è"; pertanto Top Destinations non è responsabile direttamente o indirettamente, per qualità del servizio, informazioni introdotte da Utenti, collaboratori e terze persone, danni che possano verificarsi nelle apparecchiature dell'Utente per l'utilizzo del portale. Top Destinations è esonerata, per quanto compatibile con quanto disposto dal Codice del Turismo, da  responsabilità ad essa non imputabili derivanti dal mancato o parziale adempimento degli obblighi assunti dai fornitori di prodotti o servizi e dagli organizzatori dei Pacchetti di Viaggio in conformità con la normativa vigente e con i termini e le condizioni di vendita previsti per ciascun servizio o Pacchetto di Viaggio contratto attraverso il Sito. Top Destinations non è responsabile dei danni derivanti da cancellazioni o modifiche ai Servizi dovuti da cause di forza maggiore o circostanze imprevedibili (come, a titolo meramente esemplificativo, scioperi, incendi, calamità naturali, guerre e attacchi terroristici).
                L'Utente riconosce che l'uso che farà del Sito rientra esclusivamente sotto il proprio esclusivo dominio e sotto la propria esclusiva responsabilità. Qualora nel Sito fossero presenti collegamenti ad altri siti web, l’Utente riconosce che tali collegamenti sono effettuati al solo fine di favorirlo nella ricerca di informazioni e risorse e la loro pubblicazione non implica alcun controllo, valutazione o approvazione in merito al contenuto e alle informazioni degli stessi da parte di Top Destinations.
                <List.Item>MODIFICA CONDIZIONI CONTRATTUALI</List.Item>
                Le presenti Condizioni Generali potranno essere modificate di quando in quando da Top Destinations a propria esclusiva discrezione. Gli Utenti saranno informati delle eventuali modifiche mediante pubblicazione sul Sito e tali modifiche entreranno in vigore al momento stesso della loro pubblicazione. L'uso continuato del Sito dopo l'entrata in vigore di dette modifiche/aggiornamenti ne costituisce accettazione.
                <List.Item>COOKIES</List.Item>
                In conformità con la direttiva europea 2009/136/CE, Top Destinations informa che il Sito accetta l'uso di cookies (messaggi che i server Web passano al browser web quando si visitano siti Internet e che contengono informazioni sull'ultima vita al sito web).
                <List.Item>LEGGE APPLICABILE</List.Item>
                Le presenti Condizioni Generali sono soggette alla legislazione italiana. Sarà competente, per qualunque controversia che derivi dalla loro applicazione, il Foro di Milano, con rinuncia espressa delle parti a qualunque altro foro che possa corrispondergli.
            </List>

        </div>
    );
}

export default TermsAndConditions;
