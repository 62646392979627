/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_authenticate } from '../models/Body_authenticate';
import type { DBOffer } from '../models/DBOffer';
import type { NewUser } from '../models/NewUser';
import type { Offer } from '../models/Offer';
import type { TokenModel } from '../models/TokenModel';
import type { UserPublicInfo } from '../models/UserPublicInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminService {

    /**
     * Login For Access Token
     * @param formData
     * @returns TokenModel Successful Response
     * @throws ApiError
     */
    public static authenticate(
        formData: Body_authenticate,
    ): CancelablePromise<TokenModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/token',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Offer
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createOffer(
        requestBody: Offer,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/offers/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Offer
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateOffer(
        requestBody: DBOffer,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/admin/offers/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Offer
     * @param offerId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteOffer(
        offerId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/admin/offers/delete/{offer_id}',
            path: {
                'offer_id': offerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create User
     * @param username
     * @param admin
     * @returns NewUser Successful Response
     * @throws ApiError
     */
    public static createUser(
        username: string,
        admin: boolean,
    ): CancelablePromise<NewUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/users/create',
            query: {
                'username': username,
                'admin': admin,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Users
     * @returns UserPublicInfo Successful Response
     * @throws ApiError
     */
    public static getUsers(): CancelablePromise<Array<UserPublicInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/users',
        });
    }

    /**
     * Update Password
     * @param password
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updatePassword(
        password: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/admin/users/password',
            query: {
                'password': password,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User
     * @param username
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteUser(
        username: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/admin/users/delete/{username}',
            path: {
                'username': username,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
