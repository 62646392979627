import {Alert, CustomFlowbiteTheme} from "flowbite-react";
import React from "react";
import {HiInformationCircle} from 'react-icons/hi';
import OfferForm from "../../../components/OfferForm";

function CreateOffer() {

    const CustomTheme: CustomFlowbiteTheme['alert'] = {
        "color": {
            "info": "text-primary bg-blue-100 border-primary",
        }
    }

    return (
        <div className="flex flex-col px-4 min-h-screen">
            <Alert
                theme={CustomTheme}
                additionalContent={<p className="text-base">
                    Di seguito puoi trovare un modulo da compilare con i dettagli dell'offerta di viaggio.
                    Dopo averlo compilato, procedi cliccando sul pulsante designato per finalizzare l'inserimento.
                    Eventuali errori nei dati forniti saranno segnalati al momento dell'inserimento.</p>}
                color="info"
                icon={HiInformationCircle}
                rounded>
                <span className="font-semibold text-base">Sezione dedicata alla creazione delle nuove offerte!</span>
            </Alert>

            <OfferForm />

        </div>
    );
}

export default CreateOffer;
