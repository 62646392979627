import {Accordion, Alert, Avatar, Button, CustomFlowbiteTheme, Label, TextInput} from "flowbite-react";
import {HiInformationCircle} from "react-icons/hi";
import React, {FormEvent, useState} from "react";
import {AdminService} from "../../../api";
import {dispatchResponseReceivedEvent} from "../../../events/events";
import {getUsername, hasAdminPermission} from "../../../auth/utilities";

function MyUserSettings() {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [username, setUsername] = useState<string | null>(getUsername());
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [isAdmin] = useState<boolean | null>(hasAdminPermission());


    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setIsFormSubmitted(true);

        if (validateForm()) {
            AdminService.updatePassword(newPassword)
                .then(response => {
                    dispatchResponseReceivedEvent("success", response.message);
                    resetForm();
                })
                .catch(error => {
                    console.log(error);
                    dispatchResponseReceivedEvent("error", error.body?.detail);
                });
        } else {
            console.log("Please fill in all the required fields.");
        }
    }

    function validateForm() {
        return isNewPasswordValid() && isConfirmPasswordValid() && passwordsMatch()
    }

    function isNewPasswordValid() {return newPassword.trim() !== "";}
    function isConfirmPasswordValid() {return confirmPassword.trim() !== "";}
    function passwordsMatch() {
        if (newPassword === confirmPassword) { return true; }
        else {
            setError("Le password non coincidono")
            return false;
        }
    }

    function resetForm() {
        setIsFormSubmitted(false);
        setNewPassword("");
        setConfirmPassword("");
        setError(null)
    }

    function getUsernameInitial() {
        return username?.substring(0, 1).toUpperCase() || "";
    }

    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value);
    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value);


    const CustomAlertTheme: CustomFlowbiteTheme['alert'] = {
        color: {
            info: "text-primary bg-blue-100 border-primary",
        }
    }

    const CustomAccordionTitleTheme= {
        base: "flex w-full items-center justify-between py-5 px-5 text-left font-medium text-gray-500 dark:text-gray-400",
        flush: {
            off: "hover:bg-gray-100"
        },
        open: {
            off: "rounded-lg text-gray-900",
            on: "rounded-t-lg text-gray-900 bg-gray-100 dark:bg-gray-800 dark:text-white"
        }
    }

    return (
        <div className="flex flex-col px-4 min-h-screen gap-y-4">
            <Alert
                theme={CustomAlertTheme}
                additionalContent={<p className="text-base">
                    Di seguito è possibile modificare la password del proprio account tramite l'apposito modulo.</p>}
                color="info"
                icon={HiInformationCircle}
                rounded>
                <span className="font-semibold text-base">Sezione dedicata alla gestione dell'Account personale!</span>
            </Alert>

            <div className="flex w-2/3 mx-auto gap-x-4">
                <div className="w-1/2 py-2">
                    <Avatar placeholderInitials={getUsernameInitial()} rounded>
                        <div className="space-y-1 font-medium dark:text-white">
                            <div>{username}</div>
                            <div className="text-sm text-gray-500 dark:text-gray-400">
                                {isAdmin && "Admin user"}
                            </div>
                        </div>
                    </Avatar>
                </div>

                <div className="w-1/2">
                    <Accordion collapseAll>
                        <Accordion.Panel>
                            <Accordion.Title theme={CustomAccordionTitleTheme}>Modifica Password</Accordion.Title>
                            <Accordion.Content>
                                <form className="flex-col" onSubmit={handleSubmit}>

                                    <div className="max-w-md w-full mb-2">
                                        <div className="mb-1 block">
                                            <Label htmlFor="newPassword" value="Nuova Password"/>
                                        </div>
                                        <TextInput id="newPassword" type="password" sizing="md"
                                                   value={newPassword}
                                                   color={isFormSubmitted && !isNewPasswordValid() ? "failure" : ""}
                                                   onChange={handleNewPasswordChange}/>
                                    </div>

                                    <div className="max-w-md w-full">
                                        <div className="mb-1 block">
                                            <Label htmlFor="confirmPassword" value="Conferma Password"/>
                                        </div>
                                        <TextInput id="confirmPassword" type="password" sizing="md"
                                                   value={confirmPassword}
                                                   color={isFormSubmitted && !isConfirmPasswordValid() ? "failure" : ""}
                                                   onChange={handleConfirmPasswordChange}/>
                                    </div>

                                    <div className="max-w-md w-full text-center text-red-500 my-1.5">{error || '\u00A0'}</div>

                                    <Button type="submit"
                                            size="md"
                                            className="max-w-md w-full text-primary bg-secondary hover:text-opacity-70 hover:bg-opacity-70 shadow-md">
                                        Aggiorna Password
                                    </Button>
                                </form>
                            </Accordion.Content>
                        </Accordion.Panel>
                    </Accordion>
                </div>
            </div>

        </div>
    );
}

export default MyUserSettings;
