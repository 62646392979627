import React from 'react';
import {List} from "flowbite-react";

function SecurityAndHealthAbroad() {
    return (
        <div className="m-8 min-h-screen">
            <h1 className="my-4 font-bold flex justify-center">SICUREZZA E SALUTE ALL'ESTERO</h1>

            <List className="text-black" ordered>
                Il Ministero degli Esteri fornisce consigli aggiornati su come rimanere in salute e sicurezza
                all'estero. Per gli ultimi consigli di viaggio, comprese le leggi locali e sulla sicurezza,
                oltre a informazioni su passaporti e visti, visitare www.esteri.it. I consigli possono cambiare,
                quindi controlla regolarmente gli aggiornamenti.
            </List>

        </div>
    );
}

export default SecurityAndHealthAbroad;
