import React from 'react';
import {List} from "flowbite-react";

function Supplier() {
    return (
        <div className="m-8 min-h-screen">
            <h1 className="my-4 font-bold flex justify-center">FORNITORE</h1>

            <List className="text-black" ordered>
                Le crociere e le offerte sono gestite/realizzate da Top Destinations Agenzia Viaggi
                ("Top Destinations") che opera come nostro fornitore. Top Destinations è un marchio
                di Top Vacation Srls, una società costituita in Italia con P.IVA 04218240234, la
                cui sede legale è a Verona, in V.lo Dietro Caserma Chiodo 22.
            </List>

        </div>
    );
}

export default Supplier;
