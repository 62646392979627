import React from 'react';
import {Button, Carousel} from "flowbite-react";
import './Home.css';
import CustomCard from "../../components/CustomCard";
import {useNavigate} from "react-router-dom";


function Home() {
    const navigate = useNavigate();

    function navigateToCruise() { navigate("/cruise"); }
    function navigateToOffer() { navigate("/offer"); }
    function navigateToTradeTravel() { navigate("/trade-travel"); }
    function navigateToContacts() { navigate("/contacts"); }

    return (
        <div>
            <div className="h-96 relative">
                <Carousel leftControl=" " rightControl=" ">
                    <div>
                        <img className="h-96 w-full object-cover" src="/images/home/header/home_header_1.jpg"
                             alt="home_header_1"/>
                        <div className="absolute inset-14 flex flex-col">
                            <span className="text-left font-bold text-4xl border-text">
                                Approfitta di super sconti<br/>sulle crociere
                            </span>
                            <div className="flex pt-4">
                                <Button onClick={navigateToCruise} size="xl"
                                        className="h-10 text-black bg-tertiary hover:text-opacity-70 hover:bg-opacity-70 shadow-md">
                                    Scopri di più
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img className="h-96 w-full object-cover" src="/images/home/header/home_header_2.jpg"
                             alt="home_header_2"/>
                        <div className="absolute inset-14 flex flex-col">
                            <span className="text-right font-bold text-4xl border-text">
                                Scegli tra le nostre offerte<br/>quella che fa per te
                            </span>
                            <div className="flex justify-end pt-4">
                                <Button onClick={navigateToOffer} size="xl"
                                        className="h-10 text-black bg-tertiary hover:text-opacity-70 hover:bg-opacity-70 shadow-md">
                                    Scopri di più
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img className="h-96 w-full object-cover" src="/images/home/header/home_header_3.jpg"
                             alt="home_header_3"/>
                        <div className="absolute inset-14 flex flex-col">
                            <span className="text-left font-bold text-4xl border-text">
                                Il meglio per te da 7Across
                            </span>
                            <div className="flex pt-4">
                                <Button onClick={navigateToTradeTravel} size="xl"
                                        className="h-10 text-black bg-tertiary hover:text-opacity-70 hover:bg-opacity-70 shadow-md">
                                    Scopri di più
                                </Button>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>

            <div className="bg-tertiary text-center p-4">
                <h5 className="text-xl font-bold">7Across ti offre di più.</h5>
                <p className="text-lg font-medium">
                    Oltre ai soggiorni nei residence, puoi prenotare crociere, hotel e molte altre
                    opportunità di viaggio.<br/>Lascia che i tuoi vantaggi 7Across ti portino in tutti
                    gli angoli del mondo a prezzi speciali riservati ai soci.
                </p>
            </div>

            <div className="flex justify-center my-4 mx-12 gap-4">
                <div className="w-1/3">
                    <CustomCard
                        imgSrc="/images/home/cruise_banner.jpg"
                        imgAlt="cruise_banner"
                        title="Crociere"
                        description="Siediti comodo, rilassati e salpa verso la tua prossima vacanza con offerte
                        pensate su misura per te e il tuo stile di vita. Scopri luoghi incantevoli vicino a casa
                        o vivi emozionanti avventure in giro per il mondo, c'è una crociera fatta apposta per te."
                        buttonLabel="Scopri di più"
                        buttonLink="/cruise"/>
                </div>
                <div className="w-1/3">
                    <CustomCard
                        imgSrc="/images/home/offer_banner.jpg"
                        imgAlt="offer_banner"
                        title="Offerte"
                        description="Prenota a prezzi scontati in strutture in tutto il mondo. I soci Gold
                        Advantage possono utilizzare il loro credito di deposito per ottenere extra sconti.
                        Non sei ancora socio Gold Advantage? Scopri come diventarlo e i vantaggi a te
                        riservati"
                        buttonLabel="Scopri di più"
                        buttonLink="/offer"/>
                </div>
                <div className="w-1/3">
                    <CustomCard
                        imgSrc="/images/home/trade_travel_banner.jpg"
                        imgAlt="trade_travel_banner"
                        title="Trade + Travel"
                        description="L'iscrizione al programma 7Across Gold Advantage ti consente di scambiare
                        il tuo Credito di Deposito per incredibili sconti su affitti e crociere in tutto il
                        mondo. Lasciati trasportare verso la tua prossima vacanza oggi e scopri nuovi modi
                        per esplorare il mondo."
                        buttonLabel="Scopri di più"
                        buttonLink="/trade-travel"/>
                </div>
            </div>

            <div className="p-4 text-center flex justify-center bg-gradient-to-r from-tertiary to-secondary">
                <div className="w-2/5">
                    <h5 className="text-3xl font-bold">Chiamaci per ulteriori opzioni!</h5>
                    <p className="text-xl font-medium">
                        Abbiamo moltissime opzioni di crociere ed offerte. Scopri migliaia di luoghi che puoi visitare!
                        Parla con uno dei nostri esperti di viaggio oggi stesso.
                    </p>
                    <div className="flex justify-center pt-4">
                        <Button onClick={navigateToContacts} size="xl" className="h-10 text-black bg-secondary hover:text-opacity-70 hover:bg-opacity-70 shadow-md">
                            Contattaci
                        </Button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Home;
