import {Avatar, Navbar} from "flowbite-react";
import React, {useState} from "react";
import {getUsername} from "../auth/utilities";

function CustomAdminNavBar(){
    const [username, setUsername] = useState<string | null>(getUsername());

    function getUsernameInitial() {
        return username?.substring(0, 1).toUpperCase() || "";
    }

    return (
        <Navbar className="bg-primary p-4">
            <Navbar.Brand className="mx-5">
                <img src="/7Across-logo.png" className="h-14 sm:h-14" alt="Flowbite React Logo"/>
            </Navbar.Brand>
            <Avatar size="sm" className="mx-5" placeholderInitials={getUsernameInitial()} rounded>
                <div className="space-y-1 font-medium text-tertiary">
                    <div>{username}</div>
                </div>
            </Avatar>
        </Navbar>
    )
}

export default CustomAdminNavBar;
