/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DBOffer } from '../models/DBOffer';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OffersService {

    /**
     * Get Offers
     * @returns DBOffer Successful Response
     * @throws ApiError
     */
    public static getOffers(): CancelablePromise<Array<DBOffer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/offers',
        });
    }

    /**
     * Get Offer
     * @param offerId
     * @returns DBOffer Successful Response
     * @throws ApiError
     */
    public static getOfferById(
        offerId: string,
    ): CancelablePromise<DBOffer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/offers/{offer_id}',
            path: {
                'offer_id': offerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Offers By Type
     * @param offerType
     * @returns DBOffer Successful Response
     * @throws ApiError
     */
    public static getOffersByType(
        offerType: string,
    ): CancelablePromise<Array<DBOffer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/offers/type/{offer_type}',
            path: {
                'offer_type': offerType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
