import {Alert, Button, CustomFlowbiteTheme, Modal, Table} from "flowbite-react";
import {HiInformationCircle} from "react-icons/hi";
import React, {useEffect, useState} from "react";
import {DBOffer, OffersService} from "../../../api";
import { HiOutlinePencilAlt, HiTrash } from "react-icons/hi";
import OfferForm from "../../../components/OfferForm";
import {AdminService} from "../../../api";
import {dispatchResponseReceivedEvent} from "../../../events/events";


function EditOffers() {

    const [offers, setOffers] = useState<Array<DBOffer>>([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [offerToDelete, setOfferToDelete] = useState<DBOffer>();
    const [offerToEdit, setOfferToEdit] = useState<DBOffer>();


    useEffect(() => {
        fetchOffers().then();
        document.addEventListener('offerUpdated', refreshUpdatedOffer);
        document.addEventListener('offerCreated', refreshCreatedOffer);

        return () => {
            document.removeEventListener('offerUpdated', refreshUpdatedOffer);
            document.removeEventListener('offerCreated', refreshCreatedOffer);
        };
    }, []);


    const fetchOffers = async () => {
        try {
            const response = await OffersService.getOffers();
            setOffers(response);
        } catch (error) {
            console.error("Error fetching offers:", error);
        }
    };

    function prepareOfferForEdit(offer: DBOffer) {
        setOfferToEdit(offer)
        setOpenEditModal(true)
    }

    function prepareOfferForDeletion(offer: DBOffer) {
        setOfferToDelete(offer)
        setOpenDeleteModal(true)
    }

    function deleteOffer() {
        if (offerToDelete && offerToDelete._id) {
            AdminService.deleteOffer(offerToDelete._id).then(response => {
                dispatchResponseReceivedEvent("success", response.message);
                refreshDeletedOffer();
            }).catch((error) => {
                dispatchResponseReceivedEvent("error", error.body?.detail);
            });
        }
        setOpenDeleteModal(false)
    }

    function refreshCreatedOffer() {
        fetchOffers().then();
    }

    function refreshUpdatedOffer() {
        fetchOffers().then();
        setOpenEditModal(false)
    }

    function refreshDeletedOffer() {
        fetchOffers().then();
    }

    function mapCurrencyToSymbol(currency: string) {
        switch (currency) {
            case "EUR":
                return "€";
            case "USD":
                return "$";
            case "GBP":
                return "£";
            default:
                return "";
        }
    }

    const CustomAlertTheme: CustomFlowbiteTheme['alert'] = {
        "color": {
            "info": "text-primary bg-blue-100 border-primary",
        }
    }

    const CustomTableTheme: CustomFlowbiteTheme['table'] = {
        head: {
            base: "group/head text-xs uppercase text-primary dark:text-gray-400",
            cell: {
                base: "group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg bg-tertiary dark:bg-gray-700 px-6 py-3"
            }
        }
    }

    return (
        <div className="flex flex-col px-4 min-h-screen">
            <Alert
                theme={CustomAlertTheme}
                additionalContent={<p className="text-base">
                    Di seguito puoi trovare la lista di offerte disponibili da visionare. E' possibile modificare un
                    offerta cliccando sull'icona della matita, oppure eliminarla cliccando sull'icona del cestino.</p>}
                color="info"
                icon={HiInformationCircle}
                rounded>
                <span className="font-semibold text-base">Sezione dedicata alla gestione delle offerte!</span>
            </Alert>

            <div className="mt-4 overflow-x-auto border rounded-lg shadow">
                <Table striped theme={CustomTableTheme}>
                    <Table.Head>
                        <Table.HeadCell>Name</Table.HeadCell>
                        <Table.HeadCell>Type</Table.HeadCell>
                        <Table.HeadCell>Location</Table.HeadCell>
                        <Table.HeadCell>Company</Table.HeadCell>
                        <Table.HeadCell>Night Stay</Table.HeadCell>
                        <Table.HeadCell>Base Price</Table.HeadCell>
                        <Table.HeadCell>Member Price</Table.HeadCell>
                        <Table.HeadCell>
                            <span className="sr-only">Edit</span>
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <span className="sr-only">Delete</span>
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {offers && offers.length > 0 ? (offers.map((offer, index) => (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={index}>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {offer.name}
                                </Table.Cell>
                                <Table.Cell>{offer.offer_type}</Table.Cell>
                                <Table.Cell>{offer.location}</Table.Cell>
                                <Table.Cell>{offer.company}</Table.Cell>
                                <Table.Cell>{offer.night_stay}</Table.Cell>
                                <Table.Cell>{offer.price} {mapCurrencyToSymbol(offer.currency)}</Table.Cell>
                                <Table.Cell>{offer.member_price} {mapCurrencyToSymbol(offer.currency)}</Table.Cell>
                                <Table.Cell>
                                    <button onClick={() => prepareOfferForEdit(offer)} className="text-lg text-blue-500 dark:text-cyan-500">
                                        <HiOutlinePencilAlt/>
                                    </button>
                                </Table.Cell>
                                <Table.Cell>
                                    <button onClick={() => prepareOfferForDeletion(offer)} className="text-lg text-red-500 dark:text-cyan-500">
                                        <HiTrash/>
                                    </button>
                                </Table.Cell>
                            </Table.Row>
                        ))) : (
                            <Table.Row>
                                <Table.Cell>
                                    No offers available.
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>

            <Modal size="3xl" show={openEditModal} onClose={() => setOpenEditModal(false)}>
                <Modal.Header>Modifica Offerta</Modal.Header>
                <Modal.Body>
                    {offerToEdit && <OfferForm offerToUpdate={offerToEdit} />}
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <Modal.Header>Elimina Offerta</Modal.Header>
                <Modal.Body>
                    <div className="space-y-2">
                        <div>
                            {offerToDelete !== undefined &&
                                <ul>
                                    <li className="flex">
                                        <span className="w-1/4">Nome:</span>
                                        <span className="w-3/4 italic font-semibold">{offerToDelete.name}</span>
                                    </li>
                                    <li className="flex">
                                        <span className="w-1/4">Tipo:</span>
                                        <span className="w-3/4 italic font-semibold">{offerToDelete.offer_type}</span>
                                    </li>
                                    <li className="flex">
                                        <span className="w-1/4">Destinazione:</span>
                                        <span className="w-3/4 italic font-semibold">{offerToDelete.location}</span>
                                    </li>
                                    <li className="flex">
                                        <span className="w-1/4">Compagnia:</span>
                                        <span className="w-3/4 italic font-semibold">{offerToDelete.company}</span>
                                    </li>
                                    <li className="flex">
                                        <span className="w-1/4">Permanenza:</span>
                                        <span className="w-3/4 italic font-semibold">{offerToDelete.night_stay}</span>
                                    </li>
                                    <li className="flex">
                                        <span className="w-1/4">Prezzo base:</span>
                                        <span className="w-3/4 italic font-semibold">{offerToDelete.price} {mapCurrencyToSymbol(offerToDelete.currency)}</span>
                                    </li>
                                    <li className="flex">
                                        <span className="w-1/4">Prezzo soci:</span>
                                        <span className="w-3/4 italic font-semibold">{offerToDelete.member_price} {mapCurrencyToSymbol(offerToDelete.currency)}</span>
                                    </li>
                                </ul>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="failure" onClick={() => deleteOffer()}>Elimina</Button>
                    <Button color="gray" onClick={() => setOpenDeleteModal(false)}>Annulla</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditOffers;
